.trackingInfo {
    text-align: left;
}

.trackingInfo .carierInfo {
    margin: 30px 0;
}

.trackingInfo h3 {
    font-size: 16px;
    margin-bottom: 20px;
}

.trackingInfo h3 span {
    font-weight: 500;
    padding-left: 10px;
}

.trackingInfo h3 span.delivered {
    color: #1abb1a;
}

.trackingInfo hr {
    margin: 10px 0 20px;
}

.trackingInfo table thead th {
    border: none;
    text-align: left;
    text-transform: uppercase;
}

.trackingInfo table tbody {
    border: none;
}

/* .trackingInfo table tbody td {
    border: none;
    vertical-align: middle;
    text-align: left;
} */

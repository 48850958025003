.heroCarousel {
    position: relative;
}

.heroCarousel .slick-dots {
    bottom: 20px;
}

.heroCarousel .slick-dots li button:before {
    font-size: 24px;
    opacity: 0.5;
}

.heroCarousel .slick-dots li.slick-active button:before {
    opacity: 1;
    color: var(--primColor);
}

.heroCarousel .slick-dots li,
.heroCarousel .slick-dots li button {
    width: 24px;
}

.heroCarousel .slick-next,
.heroCarousel .slick-prev {
    top: 36%;
}

.heroCarousel .slick-next {
    right: 40px;
    z-index: 1;
}

.heroCarousel .slick-prev {
    left: 40px;
    z-index: 1;
}

.heroCarousel .slick-next::before {
    font-family: 'Material Icons';
    content: '\e5e1';
    font-size: 35px;
}

.heroCarousel .slick-prev::before {
    font-family: 'Material Icons';
    content: '\e5e0';
    font-size: 35px;
}

.heroCarousel .bannerContent {
    position: absolute;
    z-index: 10;
    bottom: 20%;
    transform: translateX(-50%);
    left: 50%;
    width: 100%;
}
.heroCarousel .bannerContent h3 {
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    filter: drop-shadow(0px 1px 2px #333);
}

.heroCarousel .bannerContent h1 {
    color: #fff;
    font-size: 50px;
    font-weight: 500;
    filter: drop-shadow(0px 2px 3px #333);
}

.heroCarousel .bannerContent button {
    background: transparent;
    border-radius: 0px;
    color: #fff;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    height: 45px;
    margin-top: 15px;
}

.heroCarousel .bannerContent button .MuiButton-label {
    text-transform: initial;
}

/* .heroCarousel {
    width: 100%;
    overflow: hidden;
}

.marquee-item {
    white-space: nowrap;

    min-height: 40px;
    line-height: 40px;
} */

.marquee {
    width: 100%;
    overflow: hidden;
    /* height: 100%; */
    white-space: nowrap;
    box-sizing: border-box;
}

.marquee-content {
    display: flex;
    gap: 500px;
    /* white-space: nowrap;
    width: 100%;
    overflow: hidden; */
    padding-left: 100%;
    animation: marquee 20s linear infinite;
}
/* .marquee-item {
    animation: marquee 10s infinite linear;
} */
@keyframes marquee {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-150%);
    }
}

.rfm-marquee-container {
    justify-content: space-around;
    gap: 40px;
}
.rfm-marquee {
    width: auto;
    gap: 24px;
    justify-content: space-around;
}
.rfm-initial-child-container {
    width: 100%;
    gap: 24px;

    justify-content: space-around;
}

.rfm-child {
    width: fit-content !important;
}

.login {
    max-width: 500px;
    margin: 60px auto;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    padding: 45px;
}

.login .brandLogo {
    margin-bottom: 25px;
    width: 160px;
    height: auto;
    object-fit: contain;
}

.login h2 {
    margin: 25px 0 20px;
    font-weight: 700;
    margin-bottom: 15px;
    color: var(--primColor);
}

.login h4 {
    margin-bottom: 45px;
}

.login .loginActBox .MuiButtonBase-root.MuiListItem-root {
    padding-inline-start: 0;
}

.login .loginActBox .customCheckbox label {
    margin-bottom: 0;
}

.login .loginActBox {
    margin-bottom: 15px;
    margin-top: -15px;
}

.login .loginActBox .primButton {
    width: 160px;
}

.login .loginMiscAction {
    margin-top: 30px;
    margin-bottom: 0;
}

.login .MuiDivider-root {
    margin: 25px 0;
}

/* .login.resetPassword h2{
  margin-bottom: 35px;
} */

@media (max-width: 500px) {
    .login {
        border: none;
        margin: 30px auto;
        padding: 30px;
    }
    .login h2 {
        font-size: 24px;
    }
    .login h4 {
        font-size: 16px;
    }
    .login .brandLogo {
        width: 150px;
    }
    .login .loginActBox {
        flex-wrap: wrap;
    }
    .login .loginActBox .primButton {
        width: 100%;
    }
}

@media (max-width: 445px) {
    .login {
        padding: 15px;
    }
    .login .loginMiscAction a {
        font-size: 13px;
    }
}

@media (max-width: 380px) {
    .login h2 {
        font-size: 22px;
        margin-bottom: 5px;
    }
}

.emptyCart {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-content: center;
}

.emptyCart .emptyCartImg {
    margin: 15px auto;
}

.emptyCart h2 {
    margin: 10px 0;
    font-weight: 600;
    color: var(--primColor);
    font-size: 26px;
}

.awardingCart {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: var(--primColor);
    font-size: large;
}

.emptyCart h4 {
    font-weight: 500;
    color: var(--secColor);
    font-size: 20px;
}

.emptyCart .primButton {
    margin: 35px auto;
    width: max-content;
}

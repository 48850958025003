.paymentResponse {
    padding: 60px;
}

.paymentResponse h2 {
    font-size: 24px;
    font-weight: 700;
    color: var(--secColor);
}

.paymentResponse h4 {
    font-size: 18px;
    color: var(--secColor);
    font-weight: 600;
}

.fullScreenPopup .MuiAppBar-colorPrimary {
    background-color: #313131 !important;
    position: sticky;
    top: 0;
}

.modalChildren {
    padding: 45px 20px;
}

.fullScreenPopup .MuiToolbar-root.fspToolbar {
    flex-direction: row-reverse;
    max-width: 1250px;
    margin: 0 auto;
    width: 100%;
    padding: 0;
}

.fullScreenPopup .MuiLinearProgress-root {
    z-index: 1;
    width: 100%;
    position: fixed;
    top: 64px;
    left: -17px;
    height: 6px;
}

.fullScreenPopup .MuiLinearProgress-root .MuiLinearProgress-bar {
    background-color: var(--primColor);
}

.fullScreenPopup .MuiLinearProgress-root {
    background-color: #313131;
}

.customAlert {
    background: #ececec;
    color: #757575;
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    border-radius: 5px;
    margin: 15px 0;
}

.customAlert.error {
    background: #ffcfcf;
    color: #b31e1e;
}

.customAlert.warning {
    background: #ffd6b9;
    color: #ab5d24;
}

.customAlert.info {
    background: #ececec;
    color: #757575;
}

.customAlert.success {
    background: #cff3d7;
    color: #258c3c;
}

.customAlert h4 {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    margin-left: 10px;
}

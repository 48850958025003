.App {
    text-align: center;
    background-color: #fff;
    max-width: 2400px;
    margin-inline-start: auto !important;
    margin-inline-end: auto !important;
}

/* .MuiCollapse-container.MuiCollapse-entered,
.MuiCollapse-container.MuiCollapse-entered + div {
    z-index: 999999999999;
} */

/* div.MuiCollapse-container + div {
    z-index: 999999999999 !important;
} */

.homeMarginBottom {
    margin-bottom: 80px;
}

.home .homeTitle {
    font-size: 28px;
    font-weight: 700;
    color: var(--primColor);
    margin-bottom: 40px;
    font-family: var(--soleilBold);
    position: relative;
    text-align: left;
    text-transform: uppercase;
}

.home .homeTitle::before {
    position: absolute;
    content: '';
    bottom: -5px;
    left: 0;
    width: 100px;
    height: 3px;
    background: var(--secColor);
}

.homeHowItWorks .homeTitle::before {
    left: 45%;
}

/* BANNER */

/* .home {
    display: flex;
    align-items: center;
    flex-direction: column;
} */

.home .heroBanner {
    width: 100%;
}

.home .homeBanner {
    height: 490px;
    position: relative;
}

.home .homeBanner img {
    width: 100%;
    height: 490px;
    opacity: 0.4;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 1366 / 520;
}

.bannerImgCnt {
    background-color: black;
    margin-bottom: 32px;
    height: 490px;
}

.home .homeBanner .bannerContent {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 10%;
    text-align: left;
}

.home .homeBanner .bannerContent h1 {
    font-size: 100px;
    font-family: var(--soleilBold);
    color: #fff;
    margin: 0;
    margin-bottom: 15px;
    word-break: break-all;
}

.home .homeBanner .bannerContent h3 {
    font-size: 40px;
    font-family: var(--soleilBook);
    color: #fff;
    margin: 0;
}

.home .homeBanner .bannerContent .secButton {
    width: max-content;
    min-width: 150px;
}

.home .homeBanner .bannerContent a {
    min-width: 150px;
    font-family: var(--soleilBook);
    background: var(--secColor);
    color: #fff;
    text-transform: uppercase;
    padding: 5px 15px;
    height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.home .homeBanner .bannerContent a:hover {
    color: var(--secColor);
    background: #fff;
}

/* RECENTLY LISTED */

.homeRecentlyListed {
    text-align: start;
}

.homeRecentlyListed .loadingCnt {
    display: block;
}

.homeRecentlyListed .loadingCnt .skeletonWrapper {
    box-shadow: none;
}

.homeRecentlyListed .loadingCnt .listViewSkeleton {
    background: #fff;
}

.homeRecentlyListed .loadingCnt .listViewSkeleton .skeletonWrapper {
    min-height: auto;
}

.homeRecentlyListed .loadingCnt .listViewSkeleton {
    height: auto;
}

.homeRecentlyListed .recentItems {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 15px;
}

/* HOW IT WORKS */
.homeHowItWorks {
    background: url('../../assets//images//hiwBg.webp');
    width: 100%;
    height: 100%;
    padding: 100px 0;
    background-size: contain;
    max-width: none !important;
}

.homeHowItWorks .homeTitle {
    text-align: center;
    /* width: max-content; */
    margin-bottom: 0 auto 75px;
}

.homeHowItWorks .hiwOdd,
.homeHowItWorks .hiwEven {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.homeHowItWorks .hiwLt {
    width: 520px;
    height: 430px;
    position: relative;
}

.homeHowItWorks .hiwLt .hiwCounter {
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--secColor);
    color: #fff;
    font-size: 35px;
    font-family: var(--soleilBold);
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeHowItWorks .hiwEven .hiwLt .hiwCounter {
    right: initial;
    left: -30px;
}

.homeHowItWorks .hiwLt img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 520 / 430;
}

.homeHowItWorks .hiwRt {
    text-align: left;
    width: 100%;
    max-width: 580px;
}

.homeHowItWorks .hiwRt h3 {
    font-size: 26px;
    color: #555555;
    font-family: var(--soleilBold);
    margin-top: 0;
    margin-bottom: 15px;
}

.homeHowItWorks .hiwRt p {
    font-size: 18px;
    line-height: 26px;
    color: #555555;
    font-family: var(--soleilLight);
    margin-top: 0;
    margin-bottom: 30px;
}

.homeHowItWorks .hiwOdd .hiwLt img {
    border-left: 15px solid var(--secColor);
}

.homeHowItWorks .hiwEven {
    margin: 45px 0;
}

.homeHowItWorks .hiwEven .hiwRt {
    text-align: right;
}

.homeHowItWorks .hiwEven .hiwRt .tertButton {
    margin-left: auto;
}

.homeHowItWorks .hiwEven .hiwLt img {
    border-right: 15px solid var(--secColor);
}

.homeHowItWorks .hiwRt .tertButton {
    min-width: 150px;
    width: max-content;
}

.homeHowItWorks .hiwRt .tertButton button {
    background: #fff;
    border: none;
    color: var(--secColor);
}

.homeFAQ .MuiAccordion-root {
    border: none;
    margin-bottom: 10px;
}

.homeFAQ .MuiAccordionSummary-root {
    color: var(--primColor);
    font-family: var(--soleilRegular);
    background-color: #f6f6f6;
    min-height: 60px;
}

.homeFAQ .accTitle {
    margin: 0;
}

.homeFAQ .MuiAccordionDetails-root {
    text-align: left;
    font-size: 16px;
    color: #333333;
    line-height: 26px;
    margin-bottom: 30px;
}

@media (min-width: 1650px) {
    .homeHowItWorks {
        padding-left: 15% !important;
        padding-right: 15% !important;
    }
}

@media (min-width: 1600px) and (max-width: 1650px) {
    .homeHowItWorks {
        padding-left: 12% !important;
        padding-right: 12% !important;
    }
}

@media (max-width: 1240px) and (min-width: 800px) {
    .homeHowItWorks .hiwOdd .hiwRt {
        padding-left: 45px;
    }

    .homeHowItWorks .hiwEven .hiwRt {
        padding-right: 45px;
    }
}

@media (max-width: 1200px) {
    .home .homeBanner .hbRt img {
        width: 220px;
        height: 250px;
    }
}

@media (max-width: 1024px) {
    .home .homeBanner .hbLt {
        padding: 30px;
    }
    .home .homeBanner .hbRt {
        padding: 15px;
    }
    .home .homeBanner.homeMarginBottom {
        margin-bottom: 15px;
    }

    .home .homeBanner .hbLt h1 {
        font-size: 40px;
    }
    .homeAbout {
        flex-wrap: wrap;
    }
}

@media (max-width: 992px) {
    .home .homeBanner .hbRt img {
        width: 200px;
        height: 230px;
        object-fit: cover;
    }
    .home .homeBanner {
        height: 550px;
    }

    .home .homeBanner .bannerContent {
        position: absolute;
        top: 14%;
        left: 10%;
        text-align: left;
    }

    .homeHowItWorks .hiwOdd {
        flex-wrap: wrap;
        justify-content: center;
    }

    .homeHowItWorks .hiwEven {
        flex-wrap: wrap-reverse;
        justify-content: center;
    }

    .homeHowItWorks .hiwLt {
        margin-bottom: 20px;
    }

    .homeHowItWorks .hiwRt,
    .homeHowItWorks .hiwEven .hiwRt {
        text-align: center;
    }

    .homeHowItWorks .hiwRt .tertButton {
        margin: auto;
    }
}

@media (max-width: 991px) {
    .home .homeBanner {
        flex-wrap: wrap;
        height: auto;
    }
    .home .homeBanner .hbLt {
        padding: 60px 30px;
        text-align: center;
    }

    .home .homeBanner .hbLt > div {
        width: 100%;
    }
    .home .homeBanner .hbRt {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        width: 100%;
        padding: 0 30px;
    }
    .home .homeBanner .hbRt img {
        width: 100%;
    }
    .home .homeBanner.homeMarginBottom {
        margin-bottom: 60px;
    }

    .home .homeAbout {
        flex-direction: column-reverse;
    }

    .home .homeAbout .homeAboutLt {
        max-width: initial;
        text-align: center;
    }

    .home .homeAbout .homeAboutLt .primButton {
        width: 220px;
        margin: 0 auto;
    }
}

@media (max-width: 825px) {
    .home .homeBanner .hbRt {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
    .home .homeBanner .hbRt img {
        height: 200px;
    }
}

@media (max-width: 767px) {
    .home .homeBanner .hbRt {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }
    .homeCategorySlider .categoryItem {
        padding-inline-end: 10px;
    }
    .homeCategorySlider .categoryItem img {
        width: 100%;
    }

    .homeHowItWorks .hiwLt {
        margin-bottom: 60px;
    }
    .homeHowItWorks .hiwEven .hiwLt .hiwCounter {
        right: initial;
        left: 131px;
    }
}

@media (max-width: 660px) {
    .home .homeBanner .hbRt {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    .home .homeBanner .hbRt img {
        height: 350px;
    }
    .home .homeBanner img {
        height: 350px;
        aspect-ratio: 16/10;
    }

    .home .homeBanner .bannerContent h3 {
        font-size: 26px;
        text-align: center;
    }

    .home .homeBanner .bannerContent h1 {
        font-size: 36px;
        margin-bottom: 8px;
        text-align: center;
        word-break: break-all;
    }

    .home .homeBanner .bannerContent {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 60px;
        width: 100%;
    }

    .home .homeBanner .bannerContent .secButton {
        width: fit-content;
        min-width: 0 !important;
    }
    .bannerImgCnt {
        height: auto;
    }

    .homeHowItWorks .hiwLt .hiwCounter {
        top: 100%;
        right: 121px;
    }
}

@media (max-width: 500px) {
    .heroCarousel .slick-arrow.slick-prev:before,
    .heroCarousel .slick-arrow.slick-next:before {
        font-size: 40px !important;
    }
    .home .homeTitle {
        font-size: 20px;
    }

    .home .homeBanner.homeMarginBottom,
    .home .homeMarginBottom {
        margin-bottom: 30px;
    }
    .home .homeBanner .hbRt img {
        height: 200px;
    }
    .homeAbout .homeAboutLt {
        text-align: start;
        margin-inline-end: 0;
        border-left: none;
        padding: 25px;
        border-top: 5px solid var(--primColor);
    }
    .homeAbout .homeAboutLt h2 {
        font-size: 24px;
        text-align: start;
    }
    .homeAbout .homeAboutLt p {
        text-align: start;
    }
    .home .homeAbout .homeAboutLt .primButton {
        margin: initial;
    }
}

@media (max-width: 445px) {
    .homeAbout .homeAboutRt object {
        width: 300px;
        margin: 0 auto 30px;
    }
    .home .homeBanner .hbRt {
        display: none;
    }
    .home .homeBanner .hbLt .bannerSearch {
        flex-wrap: wrap;
    }
    .home .homeBanner .hbLt .bannerSearch input {
        width: 100%;
    }
    .home .homeBanner .hbLt .bannerSearch .primButton {
        width: 100%;
        margin-top: 5px;
    }
    .home .homeBanner .hbLt h1 {
        line-height: 50px;
    }
    .home .homeBanner .hbLt {
        padding: 40px 15px;
    }

    .home .homeCategorySlider .slick-next,
    .home .homeCategorySlider .slick-prev {
        display: none;
    }
    .homeCategorySlider .categoryItem h6 {
        margin-top: 10px;
    }
}

.btnSearch {
    width: auto;
    margin-bottom: 30px;
}

.btnSearch .primButton {
    width: max-content;
    min-width: 150px;
    float: right;
}

@media (max-width: 600px) {
    .btnSearch,
    .btnSearch .primButton {
        width: 100%;
    }
    .btnSearch {
        margin-bottom: 0;
    }
}

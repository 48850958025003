.errorPage {
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}

.errorPage {
    margin-top: 5%;
    margin-bottom: 5%;
}

.errorPage .errorPageImg {
    margin: 15px auto;
}

.errorPage h2 {
    margin: 10px 0;
    font-weight: 600;
    color: var(--primColor);
    font-size: 26px;
}

.errorPage h4 {
    font-weight: 500;
    color: var(--secColor);
    font-size: 20px;
}

.errorPage .primButton {
    margin: 35px auto;
    width: max-content;
}

@media (max-width: 600px) {
    .errorPage .errorPageImg {
        max-width: 350px;
    }
    .errorPage h2 {
        font-size: 24px;
    }
    .errorPage h4 {
        font-size: 16px;
    }
    .errorPage .primButton {
        margin: 25px auto;
    }
}

@media (max-width: 380px) {
    .errorPage .errorPageImg {
        max-width: 250px;
    }
    .errorPage h4 {
        font-size: 14px;
    }
}

.registration {
    margin: 60px 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 45px;
    border: 1px solid #e9e9e9;
    border-top: 5px solid var(--primColor);
    /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 16%); */
}

.registration .plBox {
    margin: 0 auto;
}

.registration .plBox h2 {
    font-weight: 700;
    margin-bottom: 15px;
    text-align: center;
}

.registration .plBox h4 {
    font-weight: 600;
}

.registration .brandLogo {
    width: 200px;
    margin-bottom: 30px;
}

@media (max-width: 1024px) {
    .registration {
        border-top: 1px solid #e9e9e9;
        box-shadow: none;
    }
}

@media (max-width: 500px) {
    .registration {
        border: none;
        margin: 30px auto 0;
        padding: 30px;
    }
}

@media (max-width: 445px) {
    .registration {
        padding: 15px 0;
    }
}

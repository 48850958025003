.formRadio {
    margin-bottom: 15px;
}

.formRadio p {
    padding-inline-end: 35px;
    margin: 0;
}
.formRadio label {
    margin-bottom: 0;
}

.regModal {
    max-width: 500px;
    margin: 60px auto;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    padding: 45px;
}

.regModal .brandLogo {
    margin-bottom: 25px;
    width: 160px;
    height: auto;
    object-fit: contain;
}

.regModal h2 {
    margin: 25px 0 20px;
    font-weight: 700;
    margin-bottom: 15px;
    color: var(--primColor);
}

.regModal h4 {
    margin-bottom: 45px;
}

.regModal .loginHelp {
    font-size: 16px;
    color: #373737;
    margin-bottom: 5px;
    margin-top: 15px;
}

@media (max-width: 500px) {
    .registration .plBox .regCatgCnt .catgItems .customCheckbox {
        width: 100%;
    }

    .registration .plBox h2 {
        font-size: 24px;
    }
    .registration .plBox h4 {
        font-size: 16px;
    }
    .registration .brandLogo {
        width: 150px;
    }
    .registration .plBox .regType:first-child {
        margin-inline-end: 10px;
    }
    .registration .plBox .regType label {
        width: 150px;
        height: 150px;
    }
    .registration .regSelection {
        margin-top: 30px;
    }
    .registration .formRadio p {
        padding-inline-end: 0;
        text-align: start;
        width: 100%;
        font-size: 15px;
        text-align: start;
    }
    .registration .regActBox {
        width: 100%;
    }
    .registration .plBox .regActBox .MuiListItem-root {
        flex-wrap: wrap;
    }
    .registration .plBox .regActBox .MuiListItem-root a {
        padding-inline-start: 0px;
    }
    .registration .plBox .regActBox {
        margin: 0 auto;
    }
    .registration .plBox .regActBox .primButton {
        width: 100%;
    }
    .registration .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        margin-top: 10px;
        font-size: 12px;
    }

    .regModal {
        padding: 25px;
    }
}

@media (max-width: 445px) {
    .registration .plBox .regType label img {
        width: 50px;
    }
}

@media (max-width: 380px) {
    .registration .plBox h2 {
        font-size: 22px;
        margin-bottom: 5px;
    }

    .registration .plBox .regType label {
        width: 130px;
        height: 130px;
    }
}

.cart {
    background: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
}

.cart .cartLt .cartHead {
    margin-bottom: 15px;
}
.cart .cartProducts {
    margin-bottom: 30px;
}

.cart .cartLt .cartHead h1 {
    font-size: 25px;
    color: #353535;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
}

.cart .cartLt .cartHead span {
    font-size: 14px;
    color: #999999;
    margin-inline-start: 10px;
    font-weight: 700;
}

.cart .cartLt {
    width: 68%;
}

.cart .cartRt {
    width: 30%;
    text-align: start;
    margin-bottom: 15px;
}

.cart .cartRt .cartSummary {
    background: #ffffff;
    padding: 25px;
    position: sticky;
    top: 15px;
    border: 1px dashed #a6adb4;
    border-radius: 5px;
}

.cart .cartRt .cartSummary:not(:last-child) {
    margin-bottom: 15px;
}

.cart .cartRt .cartSummary h3 {
    font-weight: 700;
    font-size: 20px;
    text-align: start;
    color: #043764;
    margin-bottom: 15px;
    text-transform: capitalize;
}

.cart .cartRt .cartSummary h5 {
    font-size: 18px;
    color: var(--primColor);
    font-weight: 600;
    margin: 0px;
    padding-inline-start: 10px;
    display: inline;
}

.cart .cartRt .cartSummary .cartSummaryValue {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart .cartRt .cartSummary .cartSummaryValue > label {
    font-weight: 600;
    font-size: 18px;
    text-align: start;
    color: #111;
    margin-bottom: 0;
}

.cart .cartRt .cartSummary .primButton {
    margin-top: 20px;
}

.cart .crtActnTitle {
    color: var(--primColor);
    font-family: 'Soleil Bold';
    font-size: 20px;
    margin: 20px 0;
}

.cart .actnGrp .tblTtle {
    border-bottom: 1px solid #f0f3f8;
}

.cart .actnGrp .tblTtle .row {
    text-align: left;
    padding-left: 62px;
    padding-top: 10px;
    padding-bottom: 8px;
    font-weight: 700;
}

.cart .loadingCnt {
    display: block;
}

.cart .loadingCnt .skeletonWrapper {
    box-shadow: none;
}

.crtActnTl {
    font-size: 20px;
    font-weight: 700;
    color: #003594;
    text-align: left;
}

@media (max-width: 1024px) {
    .cart .cartCnt {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .cart .cartLt,
    .cart .cartRt {
        width: 100%;
    }
    .cart .cartRt {
        margin-bottom: 20px;
    }
    .cart .cartRt .cartSummary {
        display: flex;
        justify-content: space-between;
    }

    .cart .cartRt .cartSummary .primButton {
        margin-top: 15px;
    }
    .cart .cartLt .cartHead h1 {
        font-size: 22px;
    }
}

@media (max-width: 600px) {
    .cart .cartRt .cartSummary {
        padding: 15px;
        flex-wrap: wrap;
    }

    .cart .cartRt .cartSummary h3,
    .cart .cartLt .cartHead h1 {
        font-size: 18px;
    }

    .cart .cartRt .cartSummary .cartSummaryValue > label,
    .cart .cartRt .cartSummary h5 {
        font-size: 15px;
    }
    .cart .cartRt .cartSummary .primButton {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .cart {
        padding-bottom: 0;
    }
    .cart .cartProducts {
        margin-bottom: 0;
    }

    .cartSummary {
        flex-wrap: wrap;
    }

    .cartSummary > div {
        width: 100%;
    }

    .cartSummary h5 {
        float: right;
    }

    .cartSummary .primButton {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .cart .cartLt .cartHead h1 {
        font-size: 18px;
    }
}

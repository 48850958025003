footer {
    background: #0b1016;
    padding-top: 50px;
    padding-bottom: 35px;
    text-align: start;
}

footer .footLogo img:first-child {
    margin-inline-end: 30px;
    width: 160px;
    object-fit: contain;
}

footer .footLogo img:last-child {
    width: 105px;
    object-fit: contain;
}

footer .footLinks {
    width: 100%;
}

footer ul {
    padding-inline-end: 15px;
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    /* width: 20%; */
}

footer .footMisc {
    width: 100%;
    max-width: 250px;
}

footer ul li {
    /* padding-bottom: 15px; */
}

footer ul li h3,
footer .footSubscribe h3 {
    font-size: 20px;
    font-family: var(--soleilBold);
    margin-top: 0;
    margin-bottom: 25px;
    color: #fff;
}

footer ul li h4 {
    font-size: 15px;
    font-family: var(--soleilBold);
    margin-top: 0;
    margin-bottom: 5px;
    color: #cbd1d9;
}

footer ul li p,
footer ul li address {
    font-size: 15px;
    margin: 0;
    color: #69717b;
}

footer ul li address {
    max-width: 180px;
    width: 100%;
}

footer .footLinks ul li:last-child {
    margin-top: 20px;
}

footer ul li a {
    color: #fff;
    position: relative;
    font-size: 15px;
    text-transform: capitalize;
}

footer ul li a:hover {
    text-decoration: none;
    color: #fff;
}

footer ul li a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

footer ul li a:hover:after {
    width: 100%;
    left: 0;
}

/* footer .footLinks ul:not(:last-child) {
    margin-inline-end: 60px;
} */

footer .footMisc .footSocial li:not(:last-child) {
    margin-inline-end: 30px;
}

footer .footMisc .footSubscribe form {
    border-radius: 5px;
    height: auto;
    flex-wrap: wrap;
}

footer .footMisc .footSubscribe form {
    margin-bottom: 5px;
}

footer .footMisc .footSubscribe form .customInput {
    margin-bottom: 0;
}

footer .footMisc .footSubscribe form .customInput {
    background: inherit;
    border: none;
    color: #fff;
    height: inherit;
}

footer .footMisc .footSubscribe form .secButton {
    margin-top: 25px;
}

footer .footMisc .footSubscribe form .MuiInput-underline:before {
    border-bottom: 1px solid #3a4450;
}

footer .footMisc .footSubscribe form .MuiInput-underline:after {
    border-bottom: 1px solid var(--secColor);
}

.footSubscribe .MuiFormLabel-root {
    color: #fff;
}

footer .footMisc .footSubscribe form .subsInp {
    align-self: stretch;
    width: 100%;
    margin-bottom: 12px;
}

footer .footMisc .footSubscribe form button {
    height: auto;
    align-self: stretch;
}

footer .footMisc .footSubscribe form .MuiFormHelperText-root.Mui-error {
    color: #000000;
    background: #ff616163;
    margin: 0;
    padding: 3px 10px;
}

footer .footMisc .footSubscribe form .MuiFilledInput-underline:before {
    border-bottom: transparent;
}

footer .footMisc .footSubscribe form .MuiFilledInput-underline:after {
    border-bottom: 1px solid var(--primColor);
}

footer .footMisc .footSubscribe form input {
    color: white;
}

footer .footMisc .footSubscribe form input:focus {
    box-shadow: none;
    border: none;
}

footer .footMisc .footSubscribe form input::placeholder {
    color: #fff;
}
footer .footMisc .footSubscribe form button {
    color: #fff;
    display: flex;
}
footer .footMisc .footSubscribe small {
    color: #dfdfdf;
}

footer .footCopyright span,
footer .footCopyright a {
    color: #5c646d;
    font-size: 15px;
}

footer .footCopyright span.footDivider {
    padding: 0 10px;
}

footer .MuiDivider-root {
    width: 100%;
    background-color: #3a4450;
    margin: 30px 0;
}

footer .footCopyright .footSocial li:not(:last-child) {
    padding-right: 25px;
}

@media (max-width: 1200px) {
    .footCnt .footLogo {
        width: 100%;
        text-align: center;
        justify-content: center;
        margin: 0 auto 40px;
    }
}

@media (max-width: 767px) {
    .footCnt .footLinks,
    .footCnt .footMisc {
        width: 100%;
    }
    footer .footMisc .footSubscribe form .subsInp {
        width: 90%;
    }
    footer .footMisc .footSubscribe button {
        min-width: auto;
    }
    footer .footSocial {
        justify-content: center !important;
        margin: 10px auto;
    }
    footer .footCopyright {
        margin-top: 25px;
    }
    footer .footMisc .footSubscribe form {
        /* flex-wrap: nowrap; */
    }

    footer .footLinks {
        flex-wrap: wrap;
    }

    footer ul {
        margin-bottom: 25px;
        width: 50%;
    }

    footer .footMisc .footSubscribe form .subsInp {
        width: 100%;
    }
}

@media (max-width: 600px) {
    footer .footMisc {
        width: 100%;
        max-width: 100%;
    }

    footer .footCopyright {
        flex-wrap: wrap-reverse;
        justify-content: center;
    }

    footer .footCopyright > div {
        margin: auto;
    }
}

@media (max-width: 445px) {
    .footCnt .footLinks {
        align-items: flex-start;
        /* justify-content: center !important; */
    }
    footer ul {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    footer .footSocial {
        flex-direction: row;
    }
    footer ul li a {
        font-size: 13px;
    }
    footer .footMisc .footSubscribe small {
        text-align: left;
        width: 100%;
        display: block;
    }
    footer .footLinks ul:not(:last-child) {
        /* margin-inline-end: 35px; */
    }

    footer .footCopyright > div {
        flex-wrap: wrap;
        justify-content: center !important;
    }
}
